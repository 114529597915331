import * as THREE from 'three';

const InfiniteGridHelperFactory = () => {
  function InfiniteGridHelper(
    this: any,
    size: number,
    color: THREE.Color,
    distance: number
  ) {
    const time = 0;
    color = color || new THREE.Color('white');
    size = size || 10;

    distance = distance || 8000;

    const geometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1);

    const material = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,

      uniforms: {
        uTime: {
          value: time,
        },
        uSize: {
          value: size,
        },
        uColor: {
          value: color,
        },
        uDistance: {
          value: distance,
        },
      },
      transparent: true,
      vertexShader: `
      varying vec3 worldPosition;
      uniform float uDistance;
      void main() {
        vec3 pos = position.xzy * uDistance;
        pos.xz += cameraPosition.xz;
        worldPosition = pos;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      }`,

      fragmentShader: `
      varying vec3 worldPosition;
      uniform float uSize;
      uniform float uTime;
      uniform vec3 uColor;
      uniform float uDistance;
      float getGrid(float size) {
        vec2 r = worldPosition.xz / size;
        vec2 gridOne = abs(fract(r - 0.5) - 0.5) / fwidth(r);
        vec2 gridTwo = abs(fract(r - uTime) - 0.5) / fwidth(r);
        float line = min(gridOne.x, gridTwo.y);
        return 1.0 - min(line, 1.0);
      }
      void main() {
        float d = 1.0 - min(distance(cameraPosition.xz, worldPosition.xz) / uDistance, 1.0);
        float grid = getGrid(uSize);
        float alpha = grid * pow(d, 3.0);
        gl_FragColor = vec4(uColor.rgb, alpha);
        gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, grid);
        if ( gl_FragColor.a <= 0.0 ) discard;
      }`,

      extensions: {
        derivatives: true,
      },
    });

    THREE.Mesh.call(this, geometry, material);

    this.frustumCulled = false;
  }

  InfiniteGridHelper.prototype = {
    ...THREE.Mesh.prototype,
    ...THREE.Object3D.prototype,
    ...THREE.EventDispatcher.prototype,
  };

  return InfiniteGridHelper;
};

export default InfiniteGridHelperFactory;
