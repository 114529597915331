
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import * as THREE from 'three';
import InfiniteGridHelper from '@/util/three-grid-helper';
import { useStore } from '@/store';
import { useViewport } from '@/router/viewport';

const InfiniteGrid = InfiniteGridHelper();

export default {
  setup() {
    const isMobile = ref(false);
    const store = useStore();
    const dimensions = useViewport();
    const root = ref<HTMLElement | null>(null);
    const speed = computed(() => store.state.space.speed / 100);
    let mousemoveHandler: ((e: MouseEvent) => void) | null = null;
    let gyroHandler: ((e: DeviceOrientationEvent) => void) | null = null;
    let renderer: THREE.WebGLRenderer | null = null;
    let camera: THREE.PerspectiveCamera | null = null;
    let plane: THREE.Mesh | null = null;

    let width = dimensions.w;
    let height = dimensions.h;
    let aspectRatio = 1;
    let scale = 1;

    const setDimensions = () => {
      if (root.value && dimensions.w && dimensions.h) {
        width = dimensions.w;
        height = dimensions.h;
        aspectRatio = width / height;
        scale = aspectRatio >= 1 ? 1.5 : 0.75;
      }
    };

    const setSizes = () => {
      if (renderer) {
        renderer.setSize(width, height);
      }
      if (camera) {
        camera.aspect = aspectRatio;
        camera.updateProjectionMatrix();
      }
      if (plane) {
        plane.scale.set(12.74 * scale, 6.82 * scale, 1);
      }
    };

    onMounted(() => {
      isMobile.value =
        typeof navigator !== 'undefined' && /Mobi/i.test(navigator.userAgent);
      const scene = new THREE.Scene();

      // const hex = 0x0a7b7f;
      const hex = 0x6d1ad9;
      const colour = new THREE.Color(hex);

      const distance = 300;
      const fov = 50;
      const offset = 10;

      let time = 0;
      setDimensions();

      const addGrid = (size: number, ypos: number) => {
        // @ts-expect-error three sucks
        const grid = new InfiniteGrid(size, colour, distance) as THREE.Mesh;
        grid.position.set(0, ypos, 0);
        scene.add(grid);
        return grid;
      };

      const g1 = addGrid(5, offset);
      const g2 = addGrid(5, -offset);

      const geometry = new THREE.PlaneGeometry();
      const material = new THREE.MeshBasicMaterial({
        alphaMap: new THREE.TextureLoader().load('logo-tier2.svg'),
        transparent: true,
        opacity: 1,
        color: hex,
        side: THREE.DoubleSide,
      });

      plane = new THREE.Mesh(geometry, material);
      // @TODO animate this!
      plane.position.set(0, 0, 70);

      camera = new THREE.PerspectiveCamera(fov, aspectRatio, 1, distance);
      camera.position.set(0, 0, 100);
      camera.lookAt(new THREE.Vector3(0, 0, 0));
      renderer = new THREE.WebGLRenderer({
        alpha: true,
      });
      plane.lookAt(camera.position);
      scene.add(plane);
      setSizes();
      if (root.value) {
        root.value.appendChild(renderer.domElement);
      }

      function animate() {
        if (renderer && camera) {
          requestAnimationFrame(animate);
          time += speed.value;
          if (time >= 1) time = 0;
          // @ts-expect-error It works?
          g1.material.uniforms.uTime.value = time;
          // @ts-expect-error It works?
          g2.material.uniforms.uTime.value = time;
          renderer.render(scene, camera);
        }
      }

      animate();

      mousemoveHandler = (e: MouseEvent) => {
        if (camera && plane) {
          const xPos = ((width / 2 - e.clientX) / width) * 5;
          const yPos = ((height / 2 - e.clientY) / width) * 2;
          camera.position.set(xPos, -yPos, 100);
          camera.lookAt(new THREE.Vector3(0, 0, 0));
          plane.lookAt(camera.position);
        }
      };

      gyroHandler = (e: DeviceOrientationEvent) => {
        if (camera && plane && e.gamma !== null && e.beta !== null) {
          const beta = Math.max(Math.min(e.beta, 120), 0);
          const yPos = (beta - 45) / 20; // -180, 180
          if (beta > 85 && beta < 95) return;
          const xPos = e.gamma / 20; // -90, 90
          camera.position.set(xPos, -yPos, 100);
          camera.lookAt(new THREE.Vector3(0, 0, 0));
          plane.lookAt(camera.position);
        }
      };

      if (isMobile.value && window.DeviceOrientationEvent && gyroHandler) {
        window.addEventListener('deviceorientation', gyroHandler);
      } else if (mousemoveHandler) {
        window.addEventListener('mousemove', mousemoveHandler);
      }
    });

    onUnmounted(() => {
      if (isMobile.value && window.DeviceOrientationEvent && gyroHandler) {
        window.removeEventListener('deviceorientation', gyroHandler);
      } else if (mousemoveHandler) {
        window.removeEventListener('mousemove', mousemoveHandler);
      }
    });

    watch(
      () => dimensions,
      () => {
        setDimensions();
        setSizes();
      },
      { deep: true }
    );

    return { root };
  },
};
